<template>
  <a
    v-if="code"
    target="_blank"
    :href="`/v2/goods/list?customer_goods_barcode=${code}`"
  >
    {{ code }}
  </a>
</template>

<script>
export default {
  name: "GoodsCode",
  props: {
    code: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped></style>
